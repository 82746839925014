<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Dashboard</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item"><a href="#">Home</a></li>
                     <li class="breadcrumb-item active">Dashboard</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- Info boxes -->
            <div class="row">
               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-comment"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">Comentários</span>
                        <span class="info-box-number">4.410</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->

               <!-- fix for small devices only -->
               <div class="clearfix hidden-md-up"></div>

               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">Assinaturas</span>
                        <span class="info-box-number">760</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->
               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">Usuários</span>
                        <span class="info-box-number">2.000</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
               <div class="col-md-12">
                  <div class="card">
                     <div class="card-header">
                        <h5 class="card-title">Relatório</h5>

                        <div class="card-tools">
                           <button type="button" class="btn btn-tool" data-card-widget="collapse">
                              <i class="fas fa-minus"></i>
                           </button>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div class="card-body">
                        <div class="row">
                           <div class="col-md-8">
                              <p class="text-center">
                                 <strong>Cupons: Novembro, 2023</strong>
                              </p>

                              <div class="chart">
                                 <!-- Sales Chart Canvas -->
                                 <canvas id="myChart" height="120" style="height: 120px;"></canvas>
                              </div>
                              <!-- /.chart-responsive -->
                           </div>
                           <!-- /.col -->

                           <div class="col-md-4">
                              <p class="text-center">
                                 <strong>Quantidade de Assinaturas - Obtidas/Meta</strong>
                              </p>

                              <div class="progress-group">
                                 AGOSTO
                                 <span class="float-right"><b>160</b>/200</span>
                                 <div class="progress progress-sm">
                                    <div class="progress-bar bg-primary" style="width: 80%"></div>
                                 </div>
                              </div>
                              <!-- /.progress-group -->

                              <div class="progress-group">
                                 SETEMBRO
                                 <span class="float-right"><b>210</b>/300</span>
                                 <div class="progress progress-sm">
                                    <div class="progress-bar bg-gradient-indigo" style="width: 75%"></div>
                                 </div>
                              </div>

                              <!-- /.progress-group -->
                              <div class="progress-group">
                                 <span class="progress-text">OUTUBRO</span>
                                 <span class="float-right"><b>320</b>/400</span>
                                 <div class="progress progress-sm">
                                    <div class="progress-bar bg-success" style="width: 60%"></div>
                                 </div>
                              </div>

                              <!-- /.progress-group -->
                              <div class="progress-group">
                                 NOVEMBRO
                                 <span class="float-right"><b>150</b>/500</span>
                                 <div class="progress progress-sm">
                                    <div class="progress-bar bg-info" style="width: 30%"></div>
                                 </div>
                              </div>
                              <!-- /.progress-group -->
                           </div>
                           <!-- /.col -->

                        </div>
                        <!-- /.row -->

                     </div>
                     <!-- ./card-body -->
                     <div class="card-footer">
                        <p class="text-center">
                           <strong>Assinaturas</strong>
                        </p>
                        <div class="row">
                           <div class="col-sm-3 col-6">
                              <div class="description-block border-right">
                                 <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 17%</span>
                                 <h5 class="description-header">R$ 1.200</h5>
                                 <span class="description-text">Agosto</span>
                              </div>
                              <!-- /.description-block -->
                           </div>
                           <!-- /.col -->
                           <div class="col-sm-3 col-6">
                              <div class="description-block border-right">
                                 <span class="description-percentage text-warning"><i class="fas fa-caret-left"></i> 0%</span>
                                 <h5 class="description-header">R$ 1.200</h5>
                                 <span class="description-text">Setembro</span>
                              </div>
                              <!-- /.description-block -->
                           </div>
                           <!-- /.col -->
                           <div class="col-sm-3 col-6">
                              <div class="description-block border-right">
                                 <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 20%</span>
                                 <h5 class="description-header">R$ 1.500</h5>
                                 <span class="description-text">Outubro</span>
                              </div>
                              <!-- /.description-block -->
                           </div>
                           <!-- /.col -->
                           <div class="col-sm-3 col-6">
                              <div class="description-block">
                                 <span class="description-percentage text-danger"><i class="fas fa-caret-down"></i> 18%</span>
                                 <h5 class="description-header">R$ 1.150</h5>
                                 <span class="description-text">Novembro</span>
                              </div>
                              <!-- /.description-block -->
                           </div>
                        </div>
                        <!-- /.row -->
                     </div>
                     <!-- /.card-footer -->
                  </div>
                  <!-- /.card -->
               </div>
               <!-- /.col -->
            </div>
            <!-- /.row -->

         </div><!--/. container-fluid -->
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
export default {
   name: "Reports",
   mounted() {
      const ctx = document.getElementById('myChart');

      new Chart(ctx, {
         type: 'bar',
         data: {
            labels: [
               'WCA10 - Wesley Silva',
               'GGA10 - Giovani Alves',
               'LGM10 - Lucas Garcia',
               'ATS10 - Antônio Cláudio',
               'HHJ10 - Hoje da Silva',
               'WER10 - Ciclano Mendes'],
            datasets: [{
               label: 'Cupons mais usados',
               data: [20, 15, 3, 50, 2, 3],
               borderWidth: 1
            }]
         },
         options: {
            scales: {
               y: {
                  beginAtZero: true
               }
            }
         }
      });
   }
}
</script>
